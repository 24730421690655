<template>
    <b-page>
      <div v-loading="loading" class="info-box">
        <div class="title">{{ infoData.title }}</div>
        <div class="info">
          <div class="time">
            <span>发布时间：{{ infoData.created_at }}</span>
          </div>
        </div>
        <div class="content" v-html="infoData.content"></div>
  
        <div>
          <c-image-list :list="infoData.images"></c-image-list>
        </div>
  
        <div class="footer" v-if="infoData.author">
          <el-link @click="onLink(infoData.url)">来源：{{ infoData.author }}</el-link>
        </div>
  
        <!-- <div class="footer">
        <div>附件</div>
        <div></div>
      </div> -->
      </div>
    </b-page>
  </template>
  
  <script>
  import CImageList from '@/components/upload/image-list'
  import { getNewsDetail } from '@/api/news'
  
  export default {
    components: {
      CImageList,
    },
    data() {
      return {
        id: null,
        loading: false,
        visible: false,
        infoData: {},
      }
    },
    watch: {
      '$route.query.id': {
        handler(id) {
          if (id) {
            this.id = id
            this.fetchData()
          }
        },
        immediate: true,
      },
    },
    methods: {
      fetchData() {
        this.loading = true
        getNewsDetail(this.id)
          .then(res => {
            this.infoData = res
          })
          .finally(() => {
            this.loading = false
          })
      },
      onLink(url) {
        if (url) {
          window.open(url)
        }
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .info-box {
    min-height: 240px;
  
    .title {
      margin-bottom: 15px;
      font-size: 18px;
      font-weight: bold;
      color: #2b2d42;
      text-align: center;
      word-break: break-all;
    }
  
    .time {
      display: flex;
      padding-bottom: 20px;
      font-size: 12px;
      color: #747e8f;
      border-bottom: 1px solid #dfe3e8;
    }
  
    .content {
      padding: 15px;
    }
  
    .footer {
      padding: 10px;
      margin-top: 10px;
      border-top: 1px solid #dfe3e8;
    }
  }
  </style>
  